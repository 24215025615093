import React from "react";

import { Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ProjectInfoDialog = (props) => {
  const { fullScreen, open, handleClose, title, techUsed, technical } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
        <Typography color="textSecondary" variant="subtitle2" gutterBottom>
          {techUsed}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component="p">
            {technical}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectInfoDialog;
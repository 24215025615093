import React from "react";

import { Box, Grid } from "@mui/material";

import { SectionHeader } from "../../global/headers";
import { ContactMap, ContactBlock } from "./";

const Contact = (props) => {
    return (
        <Box component="div" id="contact" sx={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 2,
            paddingRight: 2,
        }}>
            <SectionHeader title="Contact us" subtitle="Let's arrange a time to talk through how our software can benefit your organisation." />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch">
                <ContactBlock
                    title="Email"
                    icon="mail">
                    chris@ryedalesoftware.com
                </ContactBlock>
                <ContactBlock
                    title="Address"
                    icon="map">
                    38 Goslipgate<br />
                    Pickering<br />
                    North Yorkshire<br />
                    YO18 8DQ
                </ContactBlock>
                <ContactBlock
                    title="Phone"
                    icon="phone">
                    07788215556
                </ContactBlock>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <Box component="div" sx={{
                        margin: "20px",
                        height: "50vh",
                    }}>
                        <ContactMap />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Contact;
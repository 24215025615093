import React, { useState } from "react";

import { Box, Typography } from "@mui/material";


const Footer = (props) => {
    const [year,] = useState(new Date().getFullYear());

    return (
        <Box component="footer" sx={{
            backgroundColor: "white",
        }}>
            <Box component="div" sx={{
                textAlign: "center",
                maxWidth: 800,
                margin: "auto",
                padding: 2,
            }}>
                <Typography sx={{
                    fontSize: "10pt !important",
                }} gutterBottom>
                    ©{year} Ryedale Software. All rights reserved. Ryedale Software is a registered trade mark of Ryedale Software Ltd. Incorporated in England & Wales with Company Number 9881655. Registered Office Address: 38 Goslipgate, Pickering, North Yorkshire, YO18 8DQ. VAT No.: 347107901.
                </Typography>
            </Box>
        </Box>
    );
}

export default Footer;
const MenuItems = [
    {
        path: "home",
        text: "Home"
    },
    {
        path: "services",
        text: "About"
    },
    {
        path: "projects",
        text: "Projects"
    },
    {
        path: "contact",
        text: "Contact"
    },
];

export default MenuItems;
import React from 'react'

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  height: "100%"
};

const center = {
  lat: 54.105710,
  lng: -1.273274
};

const ContactMap = () => {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBn3L06P3Zi51MJlC2lJZSl6MI0vzsp2Yk"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
      >
        <Marker
          icon="/images/contact/loc-marker.png"
          position={{
            lat: 54.239180, // latitude to position the marker
            lng: -0.783810 // longitude to position the marker
          }}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(ContactMap)
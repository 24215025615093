import React from "react";

import { Fade } from "react-awesome-reveal";

import { Grid, Typography } from "@mui/material";

const SectionHeader = (props) => {
    const { title, subtitle } = props;

    return (
        <Grid item md={12}>
            <Fade triggerOnce={true}>
                <Typography variant="h4" sx={{
                    textAlign: "center",
                    fontWeight: 600
                }} gutterBottom>
                    {title}
                </Typography>
                <Typography sx={{
                    textAlign: "center",
                    padding: "0 25%"
                }} gutterBottom>
                    {subtitle}
                </Typography>
            </Fade>
        </Grid>
    );
}

export default SectionHeader;
import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles";

import './index.css';
import App from './App';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "'Rubik', sans-serif",
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#006fad"
    },
    secondary: {
      main: "#2196f3"
    },
    text: {
      primary: "#323336",
      secondary: "#6b6d6f",
    },
    background: {
      default: "#e8f7ff"
    }
  },
  appBar: {
    color: "primary"
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import React from "react";

import { Box, Grid, CardHeader, Avatar, Typography, Icon, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

const ContactBlock = (props) => {
    const { title, icon } = props;

    const theme = useTheme(); // Details of the current theme
    const smallScreen = useMediaQuery(theme.breakpoints.down('md')); // Whether this device has a small screen or not

    return (
        <Grid item xs={12} sm={4}>
            <Box component="div" sx={{
                margin: "auto",
                display: smallScreen ? "block": "table"
            }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{
                            backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
                        }}>
                            <Icon sx={{
                                color: "white"
                            }}>{icon}</Icon>
                        </Avatar>
                    }
                    title={title}
                />
                <Typography component="p" sx={{
                    paddingLeft: "16px"
                }}>
                    {props.children}
                </Typography>
            </Box>
        </Grid>
    );
}

export default ContactBlock;
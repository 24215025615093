import React from "react";

import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

import { SectionHeader } from "../../global/headers";
import { ServiceBlock } from "./";

const Services = (props) => {
    const theme = useTheme(); // Details of the current theme
    const smallScreen = useMediaQuery(theme.breakpoints.down('lg')); // Whether this device has a small screen or not

    return (
        <Box component="div" id="services" sx={{
            paddingTop: 5,
            paddingBottom: 5,
            flexGrow: 1
        }}>
            <SectionHeader title="How we work" subtitle="We sit down with you to build a system that suits your organisation's needs. Here's what all our systems have in common." />
            <Grid container
                justifyContent="center"
                alignItems="center">
                <Grid item xl={4} lg={6} md={12} sm={12}>
                    <Box component="img" sx={{
                        display: "block",
                        margin: "0 auto",
                        width: smallScreen ? "60%" : "100%",
                    }} src="images/services/devices.jpg"></Box>
                </Grid>
                <Grid item xl={4} lg={6} md={12} sm={12}>
                    <ServiceBlock
                        title="Flexible designs"
                        description="Your new system will work comfortably on a desktop or a mobile device, allowing you the flexibility to work from the office or remotely." />
                    <ServiceBlock
                        title="Secure and reliable"
                        description="Our solutions are built on the Microsoft platform for the best available speed, security and reliability." />
                    <ServiceBlock
                        title="Reporting built in"
                        description="We create interactive reports which will deliver key insights into how you work and how to improve." />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Services;
import React, { useCallback } from "react";

import { Link } from "react-scroll";
import { AttentionSeeker, Fade } from "react-awesome-reveal";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

const Home = (props) => {
    const theme = useTheme(); // Details of the current theme
    const smallScreen = useMediaQuery(theme.breakpoints.down('md')); // Whether this device has a small screen or not

    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    return (
        <Box component="div" id="home" sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url("/images/home/hero.png")`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            width: "100%",
            height: "calc(100% - 64px)",
            zIndex: 20,
        }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center">
                <Grid item xs={10} sm={10} md={5} lg={4}>
                    <Fade direction="left" triggerOnce={true}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: "white !important",
                                fontWeight: "600 !important"
                            }}>
                            Making your organisation more efficient
                        </Typography>
                        <Typography
                            sx={{
                                color: "white !important",
                                paddingTop: "0.5em"
                            }}>
                            We work with organisations of all sizes to create custom software solutions that help streamline your day to day activities, saving your staff time and your organisation money.
                        </Typography>
                        <AttentionSeeker effect="flash" delay={2000}>
                            <Box component={Link} to="services" sx={{
                                height: "2em",
                                width: "10em",
                                marginTop: "0.75em",
                                borderRadius: "50px",
                                background: "#fff",
                                color: (theme) => theme.palette.primary.main,
                                textTransform: "uppercase",
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "600",
                                cursor: "pointer"
                            }} spy={true} smooth={true} offset={-150} duration={500}>See more</Box>
                        </AttentionSeeker>
                    </Fade>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={4}>
                    <Box component="img" sx={{
                        width: smallScreen ? "60%" : "100%",
                        display: { xs: "none", sm: 'none', md: 'block' },
                        marginTop: smallScreen ? "2vh" : "0"
                    }} src="images/home/report.jpg"></Box>
                </Grid>
            </Grid>
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    particles: {
                        links: {
                            enable: true,
                            opacity: 0.5
                        },
                        move: {
                            enable: true
                        },
                        opacity: {
                            value: 0.5
                        },
                        size: {
                            value: 3
                        }
                    }
                }}
            />
        </Box>
    );
}

export default Home;
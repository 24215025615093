import React from "react";

import { Link } from "react-scroll";

import { AppBar, IconButton, Toolbar, Button, Hidden, Icon, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

import MenuItems from "./MenuItems";

const drawerWidth = 240;

const Header = (props) => {
    const { toggleDrawer } = props;

    const theme = useTheme(); // Details of the current theme
    const smallScreen = useMediaQuery(theme.breakpoints.down('md')); // Whether this device has a small screen or not

    return (
        <AppBar position="fixed" sx={{
            marginLeft: drawerWidth,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: "none"
        }}>
            <Toolbar>
                <Hidden smUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: 2,
                            display: smallScreen ? "block" : "none",
                        }}
                    >
                        <Icon>menu</Icon>
                    </IconButton>
                </Hidden>
                <Box component="div" sx={{
                    flexGrow: 1,
                }}>
                    <Box component="img" sx={{
                        height: 45,
                    }} src="/images/navigation/ryedalesoftware_white.png" alt="Ryedale Software"></Box>
                </Box>
                <Hidden smDown implementation="css">
                    {MenuItems.map((item, key) => {
                        return (
                            <Link key={key} to={item.path} spy={true} smooth={true} offset={-150} duration={500}>
                                <Button color="inherit" sx={{
                                    textTransform: "none"
                                }}>{item.text}</Button>
                            </Link>
                        )
                    })}
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

export default Header;
import React from "react";

import { Box } from "@mui/material";

import { Home, Services, Projects, Contact, Footer } from "./features";
import Navigation from "./global/navigation/Navigation";

const App = (props) => {
    return (
        <Box component="div" sx={{
            display: "flex",
            height: "100%",
        }}>
            <Navigation />
            <Box component="main" sx={{
                flexGrow: 1,
            }}>
                <Box component="div" sx={theme => theme.mixins.toolbar}></Box>
                <Home />
                <Services />
                <Projects />
                <Contact />
                <Footer />
            </Box>
        </Box>
    );
}

export default App;
import React from "react";

import { Box, useMediaQuery } from "@mui/material";
import { Timeline } from '@mui/lab';
import { useTheme } from "@mui/system";

import { SectionHeader } from "../../global/headers";
import { ProjectItem } from ".";

const Projects = (props) => {
    const theme = useTheme(); // Details of the current theme
    const smallScreen = useMediaQuery(theme.breakpoints.down('md')); // Whether this device has a small screen or not

    return (
        <Box component="div" id="projects" sx={{
            paddingTop: 5,
            paddingBottom: 5,
            flexGrow: 1,
            background: "#fbfeff"
        }}>
            <SectionHeader title="Projects" subtitle="Some examples of systems we have worked on recently. Tap an item if you are interested in more technical information." />

            <Timeline position={smallScreen ? "right" : "alternate"}>
                <ProjectItem
                    icon="home"
                    title="Homes for Ukraine"
                    client="Wirral Borough Council"
                    text="Customising and implementing a Case Management System to help a Local Authority manage sponsors who wished to offer their homes to Ukrainian refugees. Replaced a number of hard to manage Excel spreadsheets with a modern system with reporting and automation to save staff time."
                    techUsed="Microsoft Model-driven PowerApps, Power Automate, Granicus CRM"
                    technical="Built tables in the Microsoft Dataverse along with associated forms for data entry and integrated the Council's Granicus CRM to allow sponsors to complete their information online and have it fed directly into the system rather than by completing a Word document and manually updating records. Power Automate was also used to automate importing of records from the Central Government's Foundry system."
                />
                <ProjectItem
                    icon="videocam"
                    title="Video Booth"
                    client="Wirral Borough Council"
                    imageUrl="/images/projects/wirral-video.jpg"
                    text="Built an app to run on a touch screen device which could call into a Microsoft Teams meeting so residents accessing the device in a public library could have video calls with Council Officers who since the pandemic now often work remotely."
                    techUsed="Azure Services, React, Power Automate, Logic Apps"
                    technical="The system is built using React for the front-end, utilising Power Automate/Logic Apps to query into the Outlook calendar behind the scenes; it then uses Azure Communication Services APIs to connect directly into a Microsoft Teams call with the Council Officer joining from their existing Teams client, as they would any other call."
                />
                <ProjectItem
                    icon="store_front"
                    title="COVID-19 Grants"
                    client="Wirral Borough Council"
                    text="Built application forms and payment integrations which paid out millions of pounds in grants to businesses and support payments to residents whilst dramatically reducing admin workloads."
                    techUsed="Granicus GovService (Firmstep), .NET Core Web API"
                    technical="Integrated a workflow using Granicus CRM with integrations to government APIs to automatically validate the data provided by businesses while other Councils were doing each check by hand. Built a connector between the application process and the Council's payment systems to allow payments to be made speedily to businesses once approved, helping assure their survival through the COVID-19 pandemic."
                />
                <ProjectItem
                    icon="forest"
                    title="Building digital forms, training and advising"
                    client="Epping Forest District Council"
                    text="Working to replace a number of paper application processes with digital forms, developing integrations into back-office systems using APIs in order to reduce admin workload. Delivering training sessions on the development of Granicus CRM processes in an attempt to up-skill the new team and spread knowledge which was previously held by only a few people."
                    techUsed="Granicus GovService (Firmstep), JavaScript, HTML, Azure, SQL Server"
                    technical="Produced options papers on best practice for delivering one cohesive customer account experience as well as leveraging modern Microsoft Azure technologies to move the Council away from on-premise resources towards a cloud future."
                />
                <ProjectItem
                    icon="list_alt"
                    title="Custom Case Management System"
                    client="Wirral Borough Council"
                    imageUrl="/images/projects/wicam.jpg"
                    text="Custom-built a Case Management web app to move a number of Council services from outdated and failing Microsoft Access databases to a more modern platform to allow the introduction of reporting and automating recurring tasks."
                    techUsed="React, .NET Core, C#, Azure AD, SQL Server"
                    technical="Developed using SQL Database and .Net Core Web API back-end, React front-end and integrations with Azure AD for security; this has meant data is stored securely, is better reported on and admin costs have been greatly reduced through the improved usability of the system which has really come into its own with the increase in home-working due to COVID-19."
                />
                <ProjectItem
                    icon="school"
                    title="Pupil progress and attainment tool"
                    client="Newby Primary School, Bradford"
                    text="Contacted by the deputy-head to help put his ideas for a pupil achievement data entry and analysis tool into action. The application allows teachers to record a child's progress through their time at the school across different subjects, with comprehensive reporting tools to analyse issues or improvements."
                    techUsed="C#, WinForms, SSRS, Azure, SQL Server, CSLA"
                    technical="Built a desktop application using C# WinForms which uses the CSLA framework to deliver a cloud-based system with the data stored in Azure SQL databases. Plans for a web-based version in the future."
                />
            </Timeline>
        </Box>
    );
}

export default Projects;
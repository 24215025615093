import React, { useState } from "react";

import { Box, Icon, Card, CardActionArea, CardContent, Typography, useMediaQuery } from "@mui/material";
import { TimelineItem, TimelineContent, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector } from "@mui/lab";
import { useTheme } from "@mui/system";

import { ProjectInfoDialog } from ".";

const ProjectItem = (props) => {
  const { icon, title, client, text, imageUrl, techUsed, technical } = props;

  const [open, setOpen] = useState(false);

  const theme = useTheme(); // Details of the current theme
  const smallScreen = useMediaQuery(theme.breakpoints.down('md')); // Whether this device has a small screen or not

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ display: smallScreen ? "none" : "block" }}></TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary">
          <Icon>{icon}</Icon>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Card variant="outlined">
          <CardActionArea onClick={handleClickOpen}>
            <CardContent>
              <Typography variant="h6" component="span">
                {title}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2" gutterBottom>
                {client}
              </Typography>
              <Typography>{text}</Typography>
              {imageUrl && <Box
                component="img"
                sx={{ maxHeight: "20vh", maxWidth: "100%" }}
                src={imageUrl}
                alt="green iguana"
              />}
            </CardContent>
          </CardActionArea>
        </Card>
        <ProjectInfoDialog open={open} handleClose={handleClose} title={title} text={text} techUsed={techUsed} technical={technical} />
      </TimelineContent>
    </TimelineItem>
  );
}

export default ProjectItem;
import React from "react";

import { Fade } from "react-awesome-reveal";

import { Box, Icon, Typography } from "@mui/material";

const ServiceBlock = (props) => {
    const { title, description } = props;

    return (
        <Fade direction="up" triggerOnce={true}>
            <Box component="div" sx={{
                padding: 1.5,
            }}>
                <Typography sx={{
                    fontWeight: 600,
                    marginBottom: "1vh"
                }} variant="h5">
                    <Icon sx={{
                        margin: "-5px 5px",
                    }} color="primary">check_circle</Icon>{title}
                </Typography>
                <Typography>
                    {description}
                </Typography>
            </Box>
        </Fade>
    );
}

export default ServiceBlock;
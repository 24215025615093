import React, { Fragment, useState } from "react";

import { Header, AppMenu } from ".";

const Navigation = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleDrawerToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.target);
    }

    return (
        <Fragment>
            <Header toggleDrawer={handleDrawerToggle} />
            <AppMenu toggleDrawer={handleDrawerToggle} anchorEl={anchorEl} />
        </Fragment>
    )
}

export default Navigation;
import React from "react";

import { Link } from "react-scroll";

import { Menu, MenuItem, Typography } from "@mui/material";

import MenuItems from "./MenuItems";

const AppMenu = (props) => {
    const { anchorEl, toggleDrawer } = props;

    return (
        <nav>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={toggleDrawer}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
            >
                {MenuItems.map((page) => (
                    <Link key={page.path} activeClass="active" to={page.path} spy={true} smooth={true} offset={-70} duration={500}>
                        <MenuItem onClick={toggleDrawer}>
                            <Typography textAlign="center">{page.text}</Typography>
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </nav>
    )
}

export default AppMenu;